import React, { Component } from "react";
import Pie from "../common/pie";
import { calculatePercentage } from "./../mainpage-services/mainpageServices";
import { TailSpin } from "react-loader-spinner";

class Dashboard extends Component {
  state = {
    data: this.props.data,
  };

  render() {
    const {
      firstname,
      lastname,
      subscriptionType,
      subscriptionUntil,
      subscriptionQuantity,
      loading,
    } = this.props.data;
    return (
      <React.Fragment>
        {loading ? (
          <div className="center-loader">
            <TailSpin arialLabel="loading-indicator" color="blue" />
          </div>
        ) : (
          <div className="d-flex flex-column mt-5">
            <div className="d-flex justify-content-center">
              <h2>
                {firstname} {lastname}
              </h2>
            </div>
            <div className="d-flex justify-content-center">
              <small className="fw-bold text-secondary">Abo</small>
            </div>
            <div className="d-flex justify-content-center">
              {subscriptionType && subscriptionUntil ? (
                <Pie
                  percentage={calculatePercentage(this.props)}
                  colour="#3e0"
                />
              ) : (
                <Pie percentage={0} colour="#3e0" />
              )}
            </div>
            <div className="d-flex justify-content-center">
              {subscriptionType && subscriptionUntil ? (
                <small className="fw-bold text-secondary">
                  Abo gültig bis {subscriptionUntil}
                </small>
              ) : (
                <small className="fw-bold text-secondary">
                  Kein Abo vorhanden
                </small>
              )}
            </div>
            {subscriptionType === "10er Abo" ? (
              <div className="d-flex justify-content-center">
                <small className="fw-bold text-secondary">
                  Du hast noch {subscriptionQuantity} Trainings zur Verfügung
                </small>
              </div>
            ) : null}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Dashboard;
