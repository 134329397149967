import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NotFound from "./components/not-found";
import LoginPage from "./components/loginPage";
import About from "./components/about";
import MainPage from "./components/mainpage";
import FirstPasswordChange from "./components/firstPasswordChange";
import Trainings from "./components/trainings";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  return (
    <React.Fragment>
      <ToastContainer autoClose={900} />
      <Switch>
        <Route path="/loginpage" component={LoginPage} />
        <Route path="/about" component={About} />
        <Route path="/mainpage" component={MainPage} />
        <Route path="/er404" component={NotFound} />
        <Route path="/firstpasswordchange" component={FirstPasswordChange} />
        <Route path="/trainings" component={Trainings} />
        <Redirect from="/" exact to="/loginpage" />
        <Redirect from="" exact to="/loginpage" />
        <Redirect to="/er404" />
      </Switch>
    </React.Fragment>
  );
}

export default App;
