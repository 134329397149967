import React from "react";
import DoAuth from "./common/doAuth";
import icon76 from "../images/icons/Icon76.png";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDumbbell,
  faInfoCircle,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Dashboard from "./mainpage-components/dashboard";

class MainPage extends DoAuth {
  state = {
    isAuth: undefined,
    showMenu: false,
    renderDashboard: true,
    renderTrainings: false,
    loading: true,
  };

  componentDidMount() {
    this.doAuth();
  }

  handleLogout = () => {
    localStorage.removeItem("loginToken");
    this.setState({ isAuth: false });
  };

  render() {
    if (!localStorage.getItem("loginToken") || this.state.isAuth === false)
      return <Redirect to="/" />;
    if (this.state.password_changed === "n")
      return <Redirect to="/firstpasswordchange" />;
    return (
      <React.Fragment>
        {/* Upper Menu */}
        <div
          className="bg-dark p-2 d-flex justify-content-evenly align-items-center"
          style={{ height: "10vh", borderBottom: "1px solid white" }}
        >
          {/* Logo */}
          <img src={icon76} alt="Company Logo"></img>
          {/* Navigation Links */}
          <Link to="/about" className="btn btn-outline-info">
            <FontAwesomeIcon icon={faInfoCircle} size="2x" />
          </Link>
          <Link to="/trainings" className="btn btn-outline-success">
            <FontAwesomeIcon icon={faDumbbell} size="2x" />
          </Link>
          <button
            className="btn btn-outline-danger"
            onClick={this.handleLogout}
          >
            <FontAwesomeIcon icon={faSignOutAlt} size="2x" />
          </button>
        </div>
        <Dashboard data={this.state} />
      </React.Fragment>
    );
  }
}

export default MainPage;
