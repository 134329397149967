import React from "react";
import DoAuth from "./common/doAuth";
import { Redirect } from "react-router";
import { updatePassword } from "./common-services/updatePassword";

class FirstPasswordChange extends DoAuth {
  state = {
    updatePassword: "",
    updatePasswordConfirm: "",
    passwordChanged: false,
  };

  handleUpdatePassword = (e) => {
    this.setState({
      updatePassword: e.target.value,
    });
  };
  handleUpdatePasswordConfirm = (e) => {
    this.setState({
      updatePasswordConfirm: e.target.value,
    });
  };

  componentDidMount() {
    this.doAuth();
  }

  render() {
    if (this.state.passwordChanged === false)
      return (
        <div>
          <div className="container">
            <h2 className="mb-2 mt-5 fw-bold">
              Dein Passwort wurde noch nie geändert
            </h2>
            <h3 className="mb-4 fw-bold">Bitte neues eingeben</h3>
            <div>
              {this.state.updatePassword !==
              this.state.updatePasswordConfirm ? (
                <React.Fragment>
                  <small className="form-text text-danger">
                    Passwörter müssen übereinstimmen.
                  </small>
                  <br />
                </React.Fragment>
              ) : null}
              <label htmlFor="inputPassword" className="fw-bold">
                Neues Passwort
              </label>
              <input
                type="password"
                className="form-control"
                id="inputPassword"
                value={this.state.updatePassword}
                onChange={this.handleUpdatePassword}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="inputPasswordConfirm" className="fw-bold">
                Passwort bestätigen
              </label>
              <input
                type="password"
                className="form-control"
                id="inputPasswordConfirm"
                value={this.state.updatePasswordConfirm}
                onChange={this.handleUpdatePasswordConfirm}
              />
            </div>
            {this.state.updatePassword !== this.state.updatePasswordConfirm ? (
              <button className="btn btn-dark" disabled>
                Abschicken
              </button>
            ) : (
              <button
                className="btn btn-dark"
                onClick={() =>
                  updatePassword(this.state).then(() => {
                    this.setState({
                      passwordChanged: true,
                    });
                  })
                }
              >
                Abschicken
              </button>
            )}
          </div>
        </div>
      );
    else return <Redirect to="/mainpage" />;
  }
}

export default FirstPasswordChange;
