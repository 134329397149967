export function getCheckinBtnClass(training, state) {
  const { maxMember, loginExpireDate, loginExpireTime, loggedMembers } =
    training;
  const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, `-`);
  const currentTime = new Date().toLocaleTimeString();
  //const parsedMembers = JSON.parse(loggedMembers);
  const parsedMembers = loggedMembers
    ? JSON.parse(loggedMembers).map((member) => parseInt(member))
    : [];

  if (parsedMembers) {
    if (parsedMembers.includes(state.id)) {
      return "btn btn-danger";
    } else if (parsedMembers.length >= parseInt(maxMember)) {
      return "btn btn-danger disabled";
    } else if (
      parseInt(state.subscriptionQuantity) < 1 &&
      state.subscriptionType === "10er Abo"
    ) {
      return "btn btn-danger disabled";
    } else if (
      loginExpireDate <= currentDate &&
      loginExpireTime < currentTime
    ) {
      return "btn btn-danger disabled";
    } else if (!state.subscriptionType) {
      return "btn btn-danger disabled";
    } else if (state.subscriptionType === "Open Gym 12 Monate") {
      return "btn btn-danger disabled";
    } else if (state.subscriptionType === "Open Gym 24 Monate") {
      return "btn btn-danger disabled";
    } else {
      return "btn btn-info";
    }
  } else {
    if (
      parseInt(state.subscriptionQuantity) < 1 &&
      state.subscriptionType === "10er Abo"
    ) {
      return "btn btn-danger disabled";
    } else if (
      loginExpireDate <= currentDate &&
      loginExpireTime < currentTime
    ) {
      return "btn btn-danger disabled";
    } else if (!state.subscriptionType) {
      return "btn btn-danger disabled";
    } else if (state.subscriptionType === "Open Gym 12 Monate") {
      return "btn btn-danger disabled";
    } else if (state.subscriptionType === "Open Gym 24 Monate") {
      return "btn btn-danger disabled";
    } else {
      return "btn btn-info";
    }
  }
}

export function getCheckinBtnAttr(training, state) {
  const { maxMember, loginExpireDate, loginExpireTime, loggedMembers } =
    training;
  const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, `-`);
  const currentTime = new Date().toLocaleTimeString();
  //const parsedMembers = JSON.parse(loggedMembers);
  const parsedMembers = loggedMembers
    ? JSON.parse(loggedMembers).map((member) => parseInt(member))
    : [];

  if (parsedMembers) {
    if (parsedMembers.includes(state.id)) {
      return "Abmelden";
    } else if (parsedMembers.length >= parseInt(maxMember)) {
      return "Maximale Anzahl erreicht";
    } else if (
      parseInt(state.subscriptionQuantity) < 1 &&
      state.subscriptionType === "10er Abo"
    ) {
      return "Keine Trainings mehr";
    } else if (
      loginExpireDate <= currentDate &&
      loginExpireTime < currentTime
    ) {
      return "Anmeldefrist abgelaufen";
    } else {
      return "Anmelden";
    }
  } else {
    if (
      parseInt(state.subscriptionQuantity) < 1 &&
      state.subscriptionType === "10er Abo"
    ) {
      return "Keine Trainings mehr";
    } else if (
      loginExpireDate <= currentDate &&
      loginExpireTime < currentTime
    ) {
      return "Anmeldefrist abgelaufen";
    } else {
      return "Anmelden";
    }
  }
}

export const getCheckinBtnEvent = async (training, state) => {
  const { maxMember, loginExpireDate, loginExpireTime, loggedMembers } =
    training;
  const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, `-`);
  const currentTime = new Date().toLocaleTimeString();
  //const parsedMembers = JSON.parse(loggedMembers);
  const parsedMembers = loggedMembers
    ? JSON.parse(loggedMembers).map((member) => parseInt(member))
    : [];

  if (parsedMembers) {
    if (parsedMembers.includes(state.id)) {
      return "checkout";
    } else if (parsedMembers.length >= parseInt(maxMember)) {
      return null;
    } else if (
      parseInt(state.subscriptionQuantity) < 1 &&
      state.subscriptionType === "10er Abo"
    ) {
      return null;
    } else if (
      loginExpireDate <= currentDate &&
      loginExpireTime < currentTime
    ) {
      return null;
    } else {
      return "checkin";
    }
  } else {
    if (
      parseInt(state.subscriptionQuantity) < 1 &&
      state.subscriptionType === "10er Abo"
    ) {
      return null;
    } else if (
      loginExpireDate <= currentDate &&
      loginExpireTime < currentTime
    ) {
      return null;
    } else {
      return "checkin";
    }
  }
};
