import baseURL from "../../utils/API";
import axios from "axios";
import { toast } from "react-toastify";

export const updatePassword = async (state) => {
  if (state.updatePassword.length < 5) {
    toast.error("Passwort muss mindestens 5 Zeichen enthalten");
    return;
  } else {
    try {
      const response = await axios.post(
        baseURL + "/user/password-update.php",
        state
      );
      if (response.data.success === 1) {
        toast.success("Daten erfolgreich erfasst");
      } else if (response.data.status === 404) {
        toast.error("Keine Verbindung zum Server!");
      } else {
        toast.error("Etwas ist schiefgelaufen!");
      }
    } catch (error) {
      console.log(error);
    }
  }
};
