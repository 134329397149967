import { Component } from "react";
import axios from "axios";
import baseURL from "../../utils/API";
import { toast } from "react-toastify";

class DoAuth extends Component {
  state = {};

  doAuth = async () => {
    const loginToken = localStorage.getItem("loginToken");
    const config = {
      headers: { Authorization: `Bearer ` + loginToken },
    };
    const bodyParameters = {
      key: "value",
    };
    if (loginToken) {
      try {
        const response = await axios.post(
          baseURL + "/user/user-check.php",
          bodyParameters,
          config
        );
        if (response.data.success === 1) {
          const {
            id,
            password_changed,
            firstname,
            lastname,
            adress,
            zip_city,
            birthdate,
            phone,
            mail,
            gender,
            subscriptionType,
            subscriptionUntil,
            subscriptionQuantity,
            subscriptionPaid,
          } = response.data.user;
          this.setState({
            id: id,
            password_changed,
            firstname,
            lastname,
            adress,
            zip_city,
            birthdate,
            phone,
            mail,
            gender,
            subscriptionType,
            subscriptionUntil,
            subscriptionQuantity,
            subscriptionPaid,
            isAuth: true,
            loading: false,
          });
        } else {
          toast.info("Du wurdest abgemeldet");
          localStorage.removeItem("loginToken");
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
}

export default DoAuth;
