//calculate correct Weekday
export function calculateDay(date) {
  const daysOfWeek = [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ];
  return daysOfWeek[new Date(date).getDay()];
}

//open accordion in Trainings List
export const showTraining = async (id) => {
  const accordionToOpen = document.getElementById(id);
  if (accordionToOpen.classList.contains("show")) {
    accordionToOpen.classList.remove("show");
  } else {
    const items = document.getElementsByName("nameID");
    for (let item of items) {
      item.classList.remove("show");
    }
    accordionToOpen.classList.add("show");
    return { trainingID: id };
  }
};

//render all Members, parse Array
export function renderMmebers(array) {
  const members = JSON.parse(array);
  return members.map((member, index) => {
    return (
      <h6 className="text-secondary" key={index}>
        - {member}
      </h6>
    );
  });
}
