import React from "react";
import DoAuth from "./common/doAuth";
import icon76 from "../images/icons/Icon76.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { fetchTrainings } from "./common-services/fetchTrainings";
import { fetchPasscode } from "./common-services/fetchPasscode";
import {
  calculateDay,
  renderMmebers,
  showTraining,
} from "./training-services/trainingServices";
import { checkinTraining } from "./training-services/checkinTraining";
import { checkoutTraining } from "./training-services/checkoutTraining";
import {
  getCheckinBtnAttr,
  getCheckinBtnClass,
  getCheckinBtnEvent,
} from "./training-services/checkinButtonServices";
import { TailSpin } from "react-loader-spinner";

class Trainings extends DoAuth {
  state = {
    trainings: [],
    passcode: "",
    loading: true,
    trainingID: "",
  };

  renderTableData() {
    return this.state.trainings.map((training, index) => {
      const {
        id,
        tags,
        date,
        time,
        maxMember,
        loginExpireDate,
        loginExpireTime,
        loggedMembers,
        loggedMembersName,
      } = training;
      const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, `-`);
      if (date >= currentDate) {
        return (
          <tr key={id}>
            <td>
              <div className="accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-dark text-light fw-bold"
                      onClick={() =>
                        showTraining(id).then((res) => {
                          if (res) {
                            this.setState({
                              trainingID: res.trainingID,
                            });
                          } else {
                            this.setState({
                              trainingID: "",
                            });
                          }
                        })
                      }
                    >
                      {calculateDay(date)} {date} {time}
                    </button>
                  </h2>
                  <div
                    className="accordion-collapse collapse"
                    id={id}
                    name="nameID"
                  >
                    <div className="accordion-body bg-dark fw-bold">
                      <p className="lead text-secondary mb-4">
                        Anmeldefrist: {calculateDay(loginExpireDate)}{" "}
                        {loginExpireDate} {loginExpireTime}
                      </p>
                      <p className="fw-bold h3">Infos:</p>
                      <pre className="fw-bold text-light mb-4">{tags}</pre>
                      {loggedMembers ? (
                        <React.Fragment>
                          <p className="fw-bold h4 text-light">
                            Teilnehmer:{" "}
                            <small className="text-secondary lead">
                              Maximal: {maxMember}
                            </small>
                          </p>
                          <div className="mb-4">
                            {renderMmebers(loggedMembersName)}
                          </div>
                        </React.Fragment>
                      ) : null}
                      <button
                        className={getCheckinBtnClass(training, this.state)}
                        onClick={async () => {
                          this.setState({
                            loading: true,
                          });

                          const res = await getCheckinBtnEvent(
                            training,
                            this.state
                          );
                          if (res === "checkin") {
                            await checkinTraining(this.state);
                          } else if (res === "checkout") {
                            await checkoutTraining(this.state);
                          }

                          const updatedTrainings = await fetchTrainings();
                          this.setState(
                            {
                              trainings: updatedTrainings,
                            },
                            () => {
                              this.doAuth();
                            }
                          );
                        }}
                      >
                        {getCheckinBtnAttr(training, this.state)}
                      </button>
                      {/* <button
                        className={getCheckinBtnClass(training, this.state)}
                        onClick={() => {
                          this.setState({
                            loading: true,
                          });
                          getCheckinBtnEvent(training, this.state).then(
                            (res) => {
                              if (res === "checkin") {
                                checkinTraining(this.state).then(() => {
                                  fetchTrainings().then((res) => {
                                    this.setState(
                                      {
                                        trainings: res,
                                      },
                                      () => {
                                        this.doAuth();
                                      }
                                    );
                                  });
                                });
                              } else if (res === "checkout") {
                                checkoutTraining(this.state).then(() => {
                                  fetchTrainings().then((res) => {
                                    this.setState(
                                      {
                                        trainings: res,
                                      },
                                      () => {
                                        this.doAuth();
                                      }
                                    );
                                  });
                                });
                              } else {
                                return null;
                              }
                            }
                          );
                        }}
                      >
                        {getCheckinBtnAttr(training, this.state)}
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  componentDidMount() {
    fetchTrainings().then((res) => {
      this.setState({
        trainings: res,
        loading: false,
      });
    });
    fetchPasscode().then((res) => {
      this.setState({
        passcode: res,
      });
    });
    this.doAuth();
  }

  render() {
    const { subscriptionUntil, subscriptionType } = this.state;
    return (
      <div className="d-flex flex-column">
        {/* Header */}
        <div
          className="bg-dark p-2 d-flex justify-content-between align-items-center"
          style={{ height: "10vh" }}
        >
          <img src={icon76} alt="Company Logo"></img>
          <h2 className="text-light">Trainings</h2>
          <Link
            to="/mainpage"
            className="btn text-light"
            style={{ borderLeft: "2px solid white" }}
          >
            <FontAwesomeIcon icon={faChevronLeft} size="2x" />
          </Link>
        </div>
        <React.Fragment>
          {/* Show Loader when is fetching Data */}
          {this.state.loading ? (
            <div className="center-loader">
              <TailSpin arialLabel="loading-indicator" color="blue" />
            </div>
          ) : (
            <div>
              {!subscriptionUntil ? (
                <React.Fragment>
                  <div className="d-flex justify-content-center bg-danger">
                    <small className="fw-bold text-light">
                      Du hast kein gültiges Abo
                    </small>
                  </div>
                  <div className="d-flex justify-content-center bg-danger">
                    <small className="fw-bold text-light">
                      Löse ein Abo um dich anmelden zu können
                    </small>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {subscriptionType !== "10er Abo" ? (
                    <React.Fragment>
                      <div className="d-flex justify-content-center bg-success">
                        <small className="fw-bold text-light">
                          Pin Eingang
                        </small>
                      </div>
                      <div className="d-flex justify-content-center bg-success">
                        <small className="fw-bold text-light">
                          {this.state.passcode}
                        </small>
                      </div>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              )}
              {this.state.trainings.length > 0 ? (
                <div className="table-responsive border rounded">
                  <table className="table table-dark table-striped">
                    <thead>
                      <tr>
                        <th className="h4 fw-bold">Trainings</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderTableData()}</tbody>
                  </table>
                </div>
              ) : null}
            </div>
          )}
        </React.Fragment>
      </div>
    );
  }
}

export default Trainings;
